import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Assume you have a CSS file for styling

const Header = () => {
  return (
    <header className="header">
      <h1>Jack Perry</h1> {/* Replace with your store name or logo */}
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </nav>
    </header>
  );
};

export default Header;
