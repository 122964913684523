import React from 'react';
import './AboutPage.css'; // Import the CSS file for styling

const AboutPage = () => {
    return (
        <div className="about-container">
            {/* <h1>About</h1> */}
            <p>Jack Perry is an Australia boutique jewellery label renowned for handcrafted pieces that incorporate the highest quality materials and craftsmanship. With an emphasis on superior gems and a dedication to using local Australian gold and silver.</p>
            <p>The brand operates with a conscious production process that minimise waste and negative environmental impacts.</p>

            <h1>Orders & Terms and Conditions</h1>
            <p>Every piece is crafted to order and will be shipped within 2-4 weeks. Due to the handcrafted nature of each piece, there may be slight variations, please ask if you would like an exact replica.</p>

            <h1>Shipping</h1>
            <p>All orders are shipped using Australia Post Express. For orders within Australia, a flat rate of $20 will be charged for orders under $200, while orders over $200 will be shipped free. For international orders, a shipping fee of $30 will be applied.</p>

            <h1>Sizing</h1>
            <p>To determine your ring size, you can use a strip of paper or a piece of non-stretchy string by wrapping it snugly around the base of your finger, marking the point where it completes the circle, and then measuring the length of the string or paper.</p>

            <h1>Cleaning</h1>
            <p>To keep your jewellery shining, follow these simple steps...</p>
            <ul>
                <li>For gold and silver: soak in warm soapy water, gently scrub, rinse, and dry.</li>
                <li>For diamonds and gems: soak in warm water with dish soap, gently scrub, rinse, and dry.</li>
                <li>For pearls: wipe with a damp cloth, avoiding chemicals or soaps.</li>
            </ul>
        </div>
    );
};

export default AboutPage;