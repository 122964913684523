import React from 'react';
import './MainPage.css'; // Ensure this path is correct
import chime_ring from '../assets/images/chime_ring.png';
import chime_ring2 from '../assets/images/chime_ring2.png';
import daytona from '../assets/images/daytona.webp';
import daytona2 from '../assets/images/daytona2.png';
import path_necklace from '../assets/images/path_necklace.png';
import path_necklace2 from '../assets/images/path_necklace2.png';
import star_stud_1 from '../assets/images/star_stud_1.png'; 
import star_stud2 from '../assets/images/star_stud2.png';


const MainPage = () => {
  return (
    <div className="grid-container">
      <div className="product-card">
        <p>Chime Ring - $550</p>
        <div className="image-container">
          <img src={chime_ring} alt="Chime Ring" className="primary-image" />
          <img src={chime_ring2} alt="Chime Ring 2" className="hover-image" />
        </div>
      </div>

      <div className="product-card">
        <p>Daytona Ring - $550</p>
        <div className="image-container">
          <img src={daytona} alt="Daytona" className="primary-image" />
          <img src={daytona2} alt="Daytona 2" className="hover-image" />
        </div>
      </div>

      <div className="product-card">
        <p>Path Necklace - $150</p>
        <div className="image-container">
          <img src={path_necklace} alt="Path Necklace" className="primary-image" />
          <img src={path_necklace2} alt="Path Necklace 2" className="hover-image" />
        </div>
      </div>

      <div className="product-card">
        <p>Star Stud - $200</p> {/* Update price as needed */}
        <div className="image-container">
          <img src={star_stud_1} alt="Star Stud 1" className="primary-image" />
          <img src={star_stud2} alt="Star Stud 2" className="hover-image" />
        </div>
    </div>
    </div>

  );
};

export default MainPage;