import React from 'react';
import './Footer.css'; // Your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      {/* <p>&copy; {new Date().getFullYear()} Jack Perry</p> */}
      {/* Add any additional links or information here */}
    </footer>
  );
};

export default Footer;
