import React from 'react';
// import './ContactPage.css'; // Uncomment if you have a CSS file

function ContactPage() {
  return (
    <div>
    <br></br>
      <p>Orders available via instagram</p>
      <p>@jackperryxo</p>
    <br></br>
    <br></br>
    <br></br>
    </div>
  );
}

export default ContactPage;